import cn from 'classnames';
import { Variant } from '../button.d';
import styles from '../button.module.css';

export const getButtonClassname = (
	variant: Variant,
	disabled: boolean = false,
	className?: string,
) => {
	const isGradient = variant === 'gradient';
	const isPrimaryGradient = variant.includes('primary-gradient');
	const isSolid = variant === 'solid';
	const isText = variant === 'text';
	const isSmall = variant.includes('small');
	const isMedium = variant.includes('medium');
	const isLarge = variant.includes('large');
	const isMediumSmall = variant.includes('medsm');
	const isDefault = variant.includes('default');
	const isOutline = variant.includes('outline');
	const isOutlineTransparent = variant.includes('border-no-bg');
	const isSubtle = variant.includes('subtle');
	const isTransparent = variant.includes('transparent');

	const classes = cn(
		className,
		styles['button-focus-styles'],
		{
			[styles['standard-rounded-button']]: isSolid || isGradient,
			[styles['solid-button']]: isSolid,
			[styles['subtle']]: isSubtle,
			[styles['transparent']]: isTransparent,
			[styles['gradient-button']]: isGradient,
			[styles['text-button']]: isText,
			[styles['text-button-disabled']]: isText && disabled,
		},
		{
			[styles['large']]: isLarge,
			[styles['medium']]: isMedium,
			[styles['small']]: isSmall,
			[styles['medsm']]: isMediumSmall,
			[styles['default']]: isDefault,
			[styles['primary-gradient']]: isPrimaryGradient,
			[styles['outline']]: isOutline,
			[styles['border-no-bg']]: isOutlineTransparent,
			[styles['disabled']]: disabled,
		},
	);

	return classes;
};
